import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';

import { IconButton, Drawer, Grid, Link, Fab } from '@material-ui/core'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import AppBar from '../components/AppBar'
import Curso from '../components/Curso';
import Storage from '../services/Storage';
import { MessageOutlined } from '@material-ui/icons';

const version = require('../../package.json').version;

export default function Home(props) {
    const classes = useStyles();
    const [openInfo, setOpenInfo] = useState(false);
    const numberOfUsers = Storage.getNumberOfUsers();

    const acessoAsLeis = event => {
        event.preventDefault();
        props.history.push('/acesso-as-leis');
    }

    const duvidasESugestoes = event => {
        event.preventDefault();
        props.history.push('/duvidas-e-sugestoes');
    }

    return (
        <div className={classes.root}>
            <AppBar title="LexLudus">

                <div className={classes.online}>
                    <PeopleIcon />
                    {numberOfUsers}
                </div>

                <IconButton onClick={() => setOpenInfo(true)} aria-label="menu">
                    <MenuIcon />
                </IconButton>
            </AppBar>

            <Grid container className={classes.container}>
                <Curso />
            </Grid>

            <Drawer
                anchor="bottom"
                className={classes.modal}
                open={openInfo}
                onClose={() => setOpenInfo(false)}
            >

                <TableContainer className={classes.table}>
                    <Table size="medium" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Acesso às Leis</TableCell>
                                <TableCell align="right">
                                    <Link href="acesso-as-leis" onClick={acessoAsLeis}>
                                        Acessar
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Versão do Aplicativo</TableCell>
                                <TableCell align="right">{version}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Drawer>

            <Fab color="primary" className={classes.fab} onClick={duvidasESugestoes}>
                <MessageOutlined />
            </Fab>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '36px 0 56px 0'
    },
    online: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#1cd14f',
        borderRadius: '20px',
        padding: '4px 8px',
        color: '#FFF',
        marginLeft: 'auto',
        '& svg': {
            paddingRight: 5,
            fontSize: 24
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: '50px 0 80px 0'
    },
    listagem: {
        marginTop: 20,
        '& > div:nth-child(odd)': {
            paddingRight: 5
        },
        '& >  div:nth-child(even)': {
            paddingLeft: 5,
        },
        '& > div > div > div': {
            display: 'flex',
            // border: '1px solid #DDD',
            marginTop: 10,
            alignItems: 'center',
            padding: 10,
            borderRadius: 10
        },
        '& p': {
            margin: 0,
            paddingLeft: 10
        },
        '& p:last-child': {
            fontSize: 16,
            color: '#757575'
        }
    },
    item: {
        width: '100%',
        marginBottom: 20
    },
    actions: {
        justifyContent: 'center',
        '& > div': {
            display: 'flex'
        }
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginLeft: 6,
        '& > svg': {
            width: '.8em',
            height: '.8em'
        }
    },
    modal: {
        '& > .MuiPaper-root': {
            maxHeight: '60vh',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
        }
    },
    icon: {
        width: 28,
        height: 28
    },
    imgPosition: {
        width: 32
    },
    nome: {
        paddingLeft: 10,
        '& span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: 'calc(100vw - 124px)'
        }
    },
    fab: {
        position: 'fixed',
        bottom: 100,
        right: theme.spacing(2),
        zIndex: 99
    }
}))