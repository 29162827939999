import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IconButton, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function DenseAppBar(props) {
    return (
        <AppBar position="fixed" color="inherit" elevation={3}>
            <Container maxWidth="sm" style={{ padding: 0 }}>
                <Toolbar style={{ padding: '0 5px' }}>
                    {props.onBack && (
                        <IconButton onClick={props.onBack} edge="start" color="inherit" aria-label="menu">
                            <CloseIcon />
                        </IconButton>
                    )}
                    <Typography style={{ fontSize: props.smallTitle ? '1.1rem' : '', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="h6" color="inherit">{props.title}</Typography>
                    {props.children}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
