import { makeStyles } from '@material-ui/core';
import React from 'react'

import getConquistas from '../services/Conquistas';
import CardHorizontal from './CardHorizontal';

export default function Conquistas(props) {
    const classes = useStyles();
    const conquistas = getConquistas();

    return (
        <div className={classes.root}>
            {
                conquistas.map(e => (
                    <CardHorizontal
                        key={`conquista-${e.id}`}
                        nome={e.nome}
                        ativo={e.ganho}
                        descricao={e.ganho ? e.descricao_ganho : e.descricao}
                        foto={`https://lexludus.com.br/api/upload/conquista/${e.foto}`}
                        backgroundColor={e.ganho ? e.cor_fundo : '#DDD'}
                    />
                ))
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    container: {
        display: 'flex',
        marginTop: 10,
        width: '100%'
    },
    foto: {
        display: 'flex',
        width: 80,
        minWidth: 80,
        minHeight: 80,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 6,
        borderRadius: '4px 0 0 4px'
    },
    img: {
        width: '80%'
    },
    texto: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        '& > h1': {
            padding: '0 0 5px 0 !important',
            fontSize: 18
        },
        '& > p': {
            fontSize: 16
        }
    }
}));