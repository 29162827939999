import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Text, { Small } from '../components/Text';
import Patentes from '../services/Patentes';
import { Badge, LinearProgress, ListSubheader } from '@material-ui/core';

import Storage from '../services/Storage';
import Axios from '../services/Axios';

export default function Ranking() {
    const classes = useStyles()

    const [dados, setDados] = useState(Storage.getRanking());

    const meusPontos = Storage.getPontosUsuarioLogado();
    const meuNivel = Patentes.getByPontos(meusPontos);
    const progresso = (meusPontos - meuNivel.pontos.min) * 100 / (meuNivel.pontos.max - meuNivel.pontos.min + 1);

    useEffect(() => {
        async function fetchData() {
            const res = await Axios.get('/getRanking.php');

            if (res.data && Array.isArray(res.data)) {
                setDados(res.data);
                Storage.setRanking(res.data);
            }
        }

        fetchData();
    }, []);

    const getPosition = position => {
        if (position === 0) {
            return <img alt="1º" className={classes.imgPosition} src={require('../images/medal-1.png')} />
        } else if (position === 1) {
            return <img alt="2º" className={classes.imgPosition} src={require('../images/medal-2.png')} />
        } else if (position === 2) {
            return <img alt="3º" className={classes.imgPosition} src={require('../images/medal-3.png')} />
        } else {
            return <span style={{ fontWeight: 'bold' }}>{position + 1}</span>
        }
    }

    // const handlerVerAluno = (aluno, position) => {
        // const dadosAluno = { ...aluno, posicao: position + 1, patente: Patentes.getByPontos(aluno.pontos) }
        // setVerAluno(dadosAluno);
    // }

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid container className={classes.minhaPatente}>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.divisao} style={{ width: '80%' }}><img alt="Patente" src={meuNivel.imgBranco} /></div>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" component="h2">Nível {meuNivel.nome}</Typography>
                        <Text style={{ fontSize: '1em' }}>{meuNivel.texto}</Text>
                    </Grid>
                    {meusPontos < 8000 && <Grid item xs={12} className={classes.progresso}>
                        <BorderLinearProgress variant="determinate" value={progresso} />
                        <Small>{meusPontos}/{meuNivel.pontos.max + 1} pontos</Small>
                    </Grid>}
                </Grid>

                <List
                    subheader={
                        <ListSubheader style={{ backgroundColor: '#FFF', zIndex: 99 }} component="div">Ranking Geral</ListSubheader>
                    }
                    component="nav"
                    style={{
                        width: 'calc(100vw - 10px)', marginLeft: -10, marginRight: -10
                    }}>

                    {
                        dados.map((aluno, position) => (
                            <ListItem button style={{ padding: '10px 20px' }} key={position}>
                                <ListItemAvatar>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ marginLeft: -8, width: 42, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {getPosition(position)}
                                        </div>
                                        <Badge
                                            className={classes.badge}
                                            overlap="rectangular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={<SmallAvatar alt="Patente" src={Patentes.getByPontos(aluno.pontuacao).imgBranco} />}
                                        >
                                            <Avatar alt={aluno.alun_nome} src={Storage.getDiretorioFotos() + aluno.alun_foto} className={classes.avatar} />
                                        </Badge>
                                    </div>
                                </ListItemAvatar>
                                <ListItemText
                                    className={classes.nome}
                                    primary={aluno.alun_nome}
                                    secondary={`${aluno.pontuacao} pontos`}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Grid>

            {/* <VisualizarPerfil aluno={verAluno} callbackOnClose={setVerAluno} /> */}
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 56
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 40,
        padding: 10
    },
    divisoes: {
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    divisao: {
        width: '13%',
        '& > img': {
            width: '100%'
        }
    },
    imgPosition: {
        width: 32
    },
    nome: {
        paddingLeft: 20,
        '& span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%'
        }
    },
    minhaPatente: {
        margin: '10px 0',
        borderBottom: '1px solid #DDD',
        paddingBottom: 20
    },
    avatar: {
        width: 50,
        height: 50,
        background: '#bdbdbd'
    },
    badge: {
        '& > span': {
            position: 'absolute',
            right: 4,
            bottom: 4,
            padding: 0
        }
    },
    progresso: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        '&>span': {
            whiteSpace: 'nowrap',
            margin: '0px 0px 0px 10px',
            fontWeight: 'bold',
            color: theme.palette.primary.main
        }
    }
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        borderRadius: 0,
        // border: `2px solid ${theme.palette.background.paper}`,
        '& img': {
            objectFit: 'contain',
            // filter: 'drop-shadow(2px 1px 0 white) drop-shadow(-1px 0px 0 white)'
        }
    },
}))(Avatar);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 15,
        borderRadius: 10,
        margin: 0,
        zIndex: 1
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        zIndex: 0,
        borderRadius: 10,
        background: 'repeating-linear-gradient(45deg, #516eab, #516eab 10px, #3b5998 10px, #3b5998 20px)'
        // backgroundColor: theme.palette.primary//'#1a90ff',
    },
}))(LinearProgress);