import axios from "axios";
import Usuario from './Usuario';

const instance = axios.create({
    baseURL: 'https://lexludus.com.br/api',
    // baseURL: 'http://192.168.0.9/banco-de-questoes-api',
    // baseURL: 'http://192.168.137.128/banco-de-questoes-api',
    headers: {
        'Content-Type': 'application/json',
        'Token': Usuario.get()
    },
});

export default instance;