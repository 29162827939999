import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Backdrop, CircularProgress, TextField } from '@material-ui/core';

import Input from '../components/Input'
import Text, { Title } from '../components/Text';

import Axios from '../services/Axios';
import Alert from '../components/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        marginTop: 20,
        minHeight: 'calc(100vh + -80px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    botao: {
        marginTop: 10,
        marginBottom: 20
    },
    logo: {
        width: '80vw',
        maxWidth: 150,
        marginBottom: 70
    },
    inputs: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& > div:last-of-type': {
            marginRight: '0 !important'
        },
        '& input': {
            textAlign: 'center'
        }
    },
    centralize: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    }
}));


export default function RecuperarSenha(props) {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const inputRefs = useRef([]);

    const [erro, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [dadosAluno, setDadosAlunos] = useState(false);
    const [codigo, setCodigo] = useState(false);
    const [informarSenha, setInformarSenha] = useState(false);


    const onSubmit = async login => {
        setCarregando(true);

        const res = await Axios.post('/recuperarSenha.php', { email: login.email, etapa: 1 });

        if (res.data?.id_aluno) {
            setDadosAlunos(res.data);
        } else {
            setErro('E-mail não cadastrado.');
        }

        setCarregando(false);
    }

    const onSubmitFinal = async login => {
        setCarregando(true);

        const dados = {
            email: dadosAluno.email,
            idAluno: dadosAluno.id_aluno,
            codigo,
            senha: login.senha,
            etapa: 3
        };

        const res = await Axios.post('/recuperarSenha.php', dados);

        if (res.data) {
            setSucesso('Senha alterada com sucesso!');
        } else {
            setErro('Ops! Houve algum erro.');
        }

        voltar();
        setCarregando(false);
    }

    const enviarCodigo = async () => {
        setCarregando(true);

        const res = await Axios.post('/recuperarSenha.php', { codigo, idAluno: dadosAluno.id_aluno, etapa: 2 });

        if (res.data) {
            setInformarSenha(true);
        } else {
            setErro('Código inválido.');
            voltar();
        }

        setCarregando(false);
    }

    const voltar = () => {
        setDisabled(true);

        setTimeout(() => {
            props.history.push('/login');
        }, 2000);
    }

    const handleInputChange = (index, event) => {
        const value = event.target.value.replace(/[^0-9]/g, "").slice(0, 1);

        event.target.value = value;

        setCodigo(false);

        if (value.length === 0) return;

        const nextInput = inputRefs.current[index + 1];
        if (nextInput) {
            nextInput.focus();
        } else {
            const numeros = inputRefs.current.map(e => e.value);
            const inputCodigo = numeros.join('');

            if (inputCodigo.length === 6) {
                setCodigo(inputCodigo);
            }
        }
    };

    const handleKeyDown = (index, event) => {
        const input = event.target;
        const prevInput = inputRefs.current[index - 1];
        if (event.key === "Backspace" && !input.value && prevInput) {
            prevInput.focus();
            setCodigo(false);
        }
    };

    return (
        <Paper className={classes.root} elevation={0}>
            {
                !dadosAluno ? (
                    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.center}>
                            <Title style={{ color: '#3b5998' }}>Recuperar Senha</Title>
                        </div>

                        <Input
                            type="email"
                            placeholder="Email"
                            errors={errors}
                            inputRef={register}
                            required={true}
                        /><br /><br />

                        <Button variant="contained" color="primary" type="submit">Recuperar</Button>
                        <div className={classes.centralize}>
                            <Button className={classes.botao} onClick={() => { props.history.push('/login') }}>Cancelar</Button>
                        </div>

                    </form>
                ) : !informarSenha ? (
                    <div className={classes.form}>
                        <div className={classes.center}>
                            <Title style={{ color: '#3b5998' }}>Recuperar Senha</Title>
                            <Text>Informe o código enviado no email: {dadosAluno.email}.</Text>
                        </div>
                        <div className={classes.inputs}>
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <TextField
                                    variant="outlined"
                                    key={index}
                                    type="text"
                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                    onChange={(event) => handleInputChange(index, event)}
                                    onKeyDown={(event) => handleKeyDown(index, event)}
                                    style={{ marginRight: 10 }}
                                />
                            ))}
                        </div><br /><br />

                        <Button disabled={!codigo} variant="contained" color="primary" type="submit" onClick={enviarCodigo}>Recuperar</Button>
                        <div className={classes.centralize}>
                            <Button className={classes.botao} onClick={() => { props.history.push('/login') }}>Cancelar</Button>
                        </div>
                    </div>
                ) : (
                    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmitFinal)}>
                        <div className={classes.center}>
                            <Title style={{ color: '#3b5998' }}>Recuperar Senha</Title>
                        </div>

                        <Input
                            type="password"
                            placeholder="Nova Senha"
                            name="senha"
                            errors={errors}
                            inputRef={register}
                            required={true}
                            maxLength={20}
                            minLength={6}
                        /><br />

                        <Button variant="contained" color="primary" type="submit" disabled={disabled}>Recuperar</Button>
                        <div className={classes.centralize}>
                            <Button className={classes.botao} onClick={() => { props.history.push('/login') }}>Cancelar</Button>
                        </div>

                    </form>
                )
            }

            <Alert erro={true} open={!!erro} autoHideDuration={6000} onClose={() => setErro(false)}>
                {erro}
            </Alert>

            <Alert erro={false} open={!!sucesso} autoHideDuration={6000} onClose={() => setSucesso(false)}>
                {sucesso}
            </Alert>

            <Backdrop className={classes.backdrop} open={carregando}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Paper>
    );
}
