import Storage from "./Storage";

export default function Conquistas() {
    const conquistas = [
        {
            id: 1,
            nome: "Iniciante ágil",
            foto: "50_questoes.png",
            cor_fundo: "#fff000",
            descricao: "Responda 50 questões.",
            descricao_ganho: "As 50 primeiras são inesquecíveis!",
            questoes: 50
        },
        {
            id: 2,
            nome: "Explorador Jurídico",
            foto: "100_questoes.png",
            cor_fundo: "#ff656f",
            descricao: "Responda 100 questões.",
            descricao_ganho: "Começando a entender o mundo jurídico!",
            questoes: 100
        },
        // {
        //     id: 3,
        //     nome: "Aprendiz Dedicado",
        //     foto: "200_questoes.png",
        //     cor_fundo: "#FFFF00",
        //     descricao: "Responda 200 questões.",
        //     descricao_ganho: "O conhecimento está se acumulando!"
        // },
        // {
        //     id: 4,
        //     nome: "Estudante Assíduo",
        //     foto: "500_questoes.png",
        //     cor_fundo: "#008000",
        //     descricao: "Responda 500 questões.",
        //     descricao_ganho: "Você está no caminho certo!"
        // },
        // {
        //     id: 5,
        //     nome: "Investigador Jurídico",
        //     foto: "1000_questoes.png",
        //     cor_fundo: "#0000FF",
        //     descricao: "Responda 1000 questões.",
        //     descricao_ganho: "Você é um verdadeiro especialista!"
        // },
        // {
        //     id: 6,
        //     nome: "Mestre das Questões",
        //     foto: "2000_questoes.png",
        //     cor_fundo: "#4B0082",
        //     descricao: "Responda 2000 questões.",
        //     descricao_ganho: "Mestre em resolver questões jurídicas!"
        // },
        // {
        //     id: 7,
        //     nome: "Especialista Jurídico",
        //     foto: "3000_questoes.png",
        //     cor_fundo: "#EE82EE",
        //     descricao: "Responda 3000 questões.",
        //     descricao_ganho: "Seu conhecimento é impressionante!"
        // },
        // {
        //     id: 8,
        //     nome: "Gênio Jurídico",
        //     foto: "5000_questoes.png",
        //     cor_fundo: "#8B0000",
        //     descricao: "Responda 5000 questões.",
        //     descricao_ganho: "Nível genial de conhecimento jurídico!"
        // },
        // {
        //     id: 9,
        //     nome: "Dezena de Milhares",
        //     foto: "10000_questoes.png",
        //     cor_fundo: "#FF69B4",
        //     descricao: "Responda 10000 questões.",
        //     descricao_ganho: "Dez mil questões respondidas, incrível!"
        // },
        // {
        //     id: 10,
        //     nome: "Estrela do Direito",
        //     foto: "15000_questoes.png",
        //     cor_fundo: "#FFD700",
        //     descricao: "Responda 15000 questões.",
        //     descricao_ganho: "Você é uma estrela no mundo jurídico!"
        // }
    ];

    const total = Storage.getDesempenho().total;

    return conquistas.map(conquista => ({ ...conquista, ganho: total >= conquista.questoes }))
}