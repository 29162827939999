import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, CheckCircleOutline, ArrowForward } from '@material-ui/icons';
import Text from './Text';
import { Button, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Popover } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Storage from '../services/Storage';
import Axios from '../services/Axios';

export default function Curso() {
    const [assuntos, setAssuntos] = useState(Storage.getAssuntos());
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [assunto, setAssunto] = useState(assuntos[0]);
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            const res = await Axios.get('/getAssuntos.php');

            if (res.data && Array.isArray(res.data)) {
                setAssuntos(res.data);
                Storage.setAssuntos(res.data);
            }
        }

        fetchData();
    }, []);


    const handleClick = (event, currentAssunto) => {
        setAssunto(currentAssunto)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const questionario = () => {
        history.push('/questionario/' + assunto.assu_assunto);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.popover }}
            >
                <Text>{assunto.assu_assunto}</Text>
                <Text style={{ fontSize: 16, padding: '10px 0 0 0', marginBottom: 20 }}>{assunto.assu_descricao}</Text>
                <Button onClick={questionario} size="small" fullWidth variant="contained" color="primary">{assunto.feito ? 'Revisar +50 Pontos' : 'Começar +50 Pontos'}</Button>
            </Popover>

            <List component="nav" className={classes.lista}>
                {
                    assuntos.map(assunto => (
                        <ListItem button key={`assunto-${assunto.assu_id}`} id={`assunto-${assunto.assu_id}`} onClick={(event) => handleClick(event, assunto)}>
                            <ListItemIcon>
                                {
                                    assunto.feito ? (
                                        <CheckCircle fontSize="small" color="primary" />
                                    ) : (
                                        <CheckCircleOutline fontSize="small" color="disabled" />
                                    )
                                }
                            </ListItemIcon>
                            <ListItemText primary={<Text>{assunto.assu_assunto}</Text>} />
                            <ListItemSecondaryAction>
                                <ArrowForward fontSize="small" color="disabled" />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                }
            </List>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    timeline: {
        padding: 0
    },
    lista: {
        width: '100%'
    },
    popover: {
        padding: 20,
        width: 'calc(100% - 40px - 32px)',
        maxWidth: 600
    }
}));

