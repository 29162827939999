export default class Usuario {
    static USUARIO = 'ebd2.0@usuario'

    static set(usuario) {
        localStorage.setItem(Usuario.USUARIO, JSON.stringify(usuario))
    }

    static sair() {
        localStorage.removeItem(Usuario.USUARIO)
    }

    static get() {
        return JSON.parse(localStorage.getItem(Usuario.USUARIO) || 'false')
    }

    static isLogado() {
        return !!Usuario.get()
    }
}
