import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Title } from '../components/Text';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function PageNotFound() {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <div className={classes.img}>
                    <img src={require('../images/erro-404.png')} alt="Página não encontrada" />
                    <Title>Ops! Página não encontrada.</Title>
                </div>
                <Button onClick={() => history.push('/')} variant="contained" color="primary" disableElevation size="large" fullWidth>Voltar à tela inicial</Button>
            </Grid>
        </div >
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: '100vh'
    },
    img: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        height: '90vh',
        backgroundColor: theme.palette.background.paper,
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}))
