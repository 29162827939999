import React from 'react';
import { Skeleton } from '@material-ui/lab';

export default function SkeletonRanking() {
    return (
        <React.Fragment>
            {[1, 2, 3, 4].map(e => (
                <React.Fragment key={e}>
                    <Skeleton animation="wave" height={50} width={50} variant="circle" style={{ marginTop: 15, marginLeft: 20, marginRight: 10, float: 'left' }} />
                    <Skeleton animation="wave" height={50} width="calc(100% - 80px)" variant="rect" style={{ marginTop: 15 }} />
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}