import React from 'react';
import { Skeleton } from '@material-ui/lab';

export default function SkeletonQuestionario() {
    return (
        <React.Fragment>
            <Skeleton animation="wave" height={200} variant="rect" style={{ marginTop: 10 }} width="100%" />
            <Skeleton animation="wave" height={90} variant="rect" style={{ marginTop: 10 }} width="100%" />
            <Skeleton animation="wave" height={90} variant="rect" style={{ marginTop: 10 }} width="100%" />
            <Skeleton animation="wave" height={90} variant="rect" style={{ marginTop: 10 }} width="100%" />
            <Skeleton animation="wave" height={90} variant="rect" style={{ marginTop: 10 }} width="100%" />
        </React.Fragment>
    )
}