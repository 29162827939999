import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const Timer = forwardRef((props, ref) => {
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (isActive) {
            intervalRef.current = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);
    }, [isActive, seconds]);

    const handleReset = () => {
        setIsActive(false);
        setSeconds(0);
        clearInterval(intervalRef.current);
    };

    useImperativeHandle(ref, () => ({
        reset: () => handleReset()
    }));

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div style={{
            color: '#33cccc',
            fontWeight: 'bold',
            paddingLeft: 2,
            paddingRight: 10
        }}>{formatTime(seconds)}</div>
    );
});

export default Timer;
