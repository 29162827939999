import React from 'react'
import { makeStyles } from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';

export default function Avaliacao(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StarRateIcon className={props.avaliacao >= 1 ? classes.activeStar : classes.star} />
            <StarRateIcon className={props.avaliacao >= 2 ? classes.activeStar : classes.star} />
            <StarRateIcon className={props.avaliacao >= 3 ? classes.activeStar : classes.star} />
            <StarRateIcon className={props.avaliacao >= 4 ? classes.activeStar : classes.star} />
            <StarRateIcon className={props.avaliacao >= 5 ? classes.activeStar : classes.star} />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
    },
    star: {
        fontSize: '1.3em',
        color: '#bdbdbd',
        marginLeft: -4
    },
    activeStar: {
        fontSize: '1.3em',
        color: '#ffb400',
        marginLeft: -4
    }
}))