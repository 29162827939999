import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { IconButton, Button, LinearProgress, Paper, Container } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import FavoriteIconActive from '@material-ui/icons/Favorite';

import { Small } from '../components/Text'
import DenseAppBar from '../components/AppBar'
import FormControl from '@material-ui/core/FormControl';
import { Alert, AlertTitle, ToggleButton } from '@material-ui/lab'
import { useEffect } from 'react'
import AudioPlayer from '../components/AudioPlayer'
import { useRef } from 'react'
import Axios from '../services/Axios';
import SkeletonQuestionario from '../components/SkeletonQuestionario'
import Timer from '../components/Timer'

const abcdario = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
const NUM_ACERTOS = 5;
const NUM_ERROS = 10;

export default function Quationario(props) {
    const history = useHistory();
    const classes = useStyles();

    const { assunto } = useParams();

    const [questoes, setQuestoes] = useState([]);
    const [index, setIndex] = useState(0);
    const [enviado, setEnviado] = useState(false);

    const [acertos, setAcertos] = useState(0);
    const [erros, setErros] = useState(0);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const quantidade = NUM_ACERTOS + NUM_ERROS - 1;
        const response = await Axios.get('getQuestoes.php', { params: { assunto, quantidade } });

        if (response.data)
            setQuestoes(response.data);
    }

    const enviar = () => {
        if (!enviado) {
            enviarAPI(index);
            setEnviado(true);
            return;
        }

        setEnviado(false);

        if (erros < NUM_ERROS && acertos < NUM_ACERTOS) {
            setIndex(index + 1);
        } else {
            const sucesso = acertos === NUM_ACERTOS;

            if (sucesso)
                Axios.post('/salvarAssuntoFeito.php', { assunto });

            history.replace('/resumo', { acertos, erros, sucesso });
        }

    }

    const responder = (questao, alternativa) => {
        const selecionado = questao.resposta !== alternativa.id;

        const newQuestoes = questoes.map(q => {
            if (q.id === questao.id)
                q.resposta = selecionado ? alternativa.id : null;

            return q;
        })

        setQuestoes(newQuestoes);
    }

    const riscar = (questao, alternativa) => {
        const newQuestoes = questoes.map(quest => {
            if (quest.id === questao.id) {
                quest.alternativas.map(alt => {
                    if (alt.id === alternativa.id)
                        alt.riscada = !alt.riscada;

                    return alt;
                })
            }

            return quest;
        })

        setQuestoes(newQuestoes);
    }

    const getStyleResposta = (questao, alternativa) => {

        const style = {
            color: '',
            textDecoration: ''
        }

        if (alternativa.riscada)
            style.textDecoration = 'line-through';

        if (!enviado) return style;

        if (questao.resposta === alternativa.id) {
            style.color = alternativa.correta ? '#36b37e' : '#ff000a';
        } else if (questao.resposta && alternativa.correta) {
            style.color = '#36b37e';
        }

        return style;
    }

    const enviarAPI = i => {
        const questao = questoes[i];
        const alternativaCorreta = questao.alternativas.find(alternativa => alternativa.correta);
        const acertou = questao.resposta === alternativaCorreta.id;

        audio(acertou)

        if (acertou)
            setAcertos(acertos + 1);
        else
            setErros(erros + 1);

        Axios.post('/salvarResposta.php', { idAlternativa: questao.resposta });
    }

    // áudio...
    const audioRef = useRef(null);
    const audio = sucesso => {
        if (sucesso)
            audioRef.current.playSuccess();
        else
            audioRef.current.playError();
    }

    // timer...
    const timerRef = useRef(null);
    // const resetTimer = () => {
    //     if (timerRef.current) {
    //         timerRef.current.reset();
    //     }
    // }

    return (
        <div className={classes.root}>
            <DenseAppBar>
                <Link to="/" disabled>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <CloseIcon />
                    </IconButton>
                </Link>
                <BorderLinearProgress variant="determinate" value={acertos * 100 / NUM_ACERTOS} />
                <img style={{ width: 30 }} src={require('../images/icons/clock.gif')} />
                {/* <img style={{ width: 30 }} src={require('../images/icons/timer.gif')} /> */}
                {/* <TimerIcon style={{ color: '#33CCCC' }} className={classes.lifeIcon} /> */}
                <Timer ref={timerRef} />
                <FavoriteIconActive className={classes.lifeIcon} /> <span className={classes.lifeAmount}>{NUM_ERROS - erros}</span>
            </DenseAppBar>

            <Grid container className={classes.container}>


                {
                    questoes.length > 0 ? (
                        <Grid container item xs={12}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <Small className={classes.questao}>{questoes[index].questao}</Small>
                                {
                                    questoes[index].alternativas.map((alternativa, i) => (
                                        <Small
                                            style={getStyleResposta(questoes[index], alternativa)}
                                            className={classes.alternativa} key={`alt-${alternativa.id}`}>
                                            <ToggleButton
                                                disabled={enviado || alternativa.id === questoes[index].resposta}
                                                className={classes.scissors}
                                                value={alternativa.id}
                                                selected={alternativa.riscada}
                                                onClick={() => riscar(questoes[index], alternativa)}
                                            >
                                                <img src={require('../images/icons/scissors.png')} />
                                            </ToggleButton>
                                            <ToggleButton
                                                disabled={enviado || alternativa.riscada}
                                                className={classes.radio}
                                                value={alternativa.id}
                                                selected={questoes[index].resposta === alternativa.id}
                                                onClick={() => responder(questoes[index], alternativa)}
                                            >
                                                {abcdario[i]}
                                            </ToggleButton>
                                            {alternativa.alternativa}
                                        </Small>
                                    ))
                                }
                            </FormControl>
                            {enviado && (
                                <React.Fragment>
                                    <Alert severity="info" className={classes.justificativa}>
                                        <AlertTitle className={classes.tituloJustificativa}>Justificativa</AlertTitle>
                                        <Small>{questoes[index].fundamentacao}</Small>
                                    </Alert>
                                </React.Fragment>
                            )}
                        </Grid>
                    ) : (
                        <SkeletonQuestionario />
                    )
                }

                <Paper className={classes.bottomPaper} elevation={3}>
                    <Container maxWidth="sm" className={classes.bottomContainer}>
                        <Button onClick={enviar} disabled={questoes.length === 0 || !questoes[index].resposta} fullWidth variant="contained" color="primary">
                            {enviado ? (acertos === NUM_ACERTOS || erros === NUM_ERROS ? 'Finalizar' : 'Continuar') : 'Responder'}
                        </Button>
                    </Container>
                </Paper>
            </Grid>

            <AudioPlayer ref={audioRef} />
        </div >
    )
}


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 15,
        borderRadius: 10,
        margin: '0 10px 0 10px',
        zIndex: 1
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        zIndex: 0,
        borderRadius: 10,
        background: 'repeating-linear-gradient(45deg, #516eab, #516eab 10px, #3b5998 10px, #3b5998 20px)'
        // backgroundColor: theme.palette.primary//'#1a90ff',
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0'
    },
    bottomPaper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: '10px 0 16px 0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    },
    bottomContainer: {
        width: 'calc(100% - 20px)',
        margin: 0
    },
    container: {
        // backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: '10px'
    },
    progresso: {
        width: '100%',
        height: 5
    },
    questao: {
        margin: '20px 0'
    },
    alternativa: {
        padding: '10px 10px 10px 12px',
        transition: '.2s',
        '&:hover': {
            cursor: 'pointer',
            opacity: '.9'
        }
    },
    scissors: {
        width: 32,
        height: 32,
        padding: 6,
        borderRadius: 16,
        marginRight: 8,
        transition: '0s',
        '& img': {
            width: '100%'
        }
    },
    radio: {
        width: 32,
        height: 32,
        borderRadius: 16,
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 8,
        transition: '0s',
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    justificativa: {
        marginTop: 20,
        width: '100%'
    },
    tituloJustificativa: {
        fontSize: 18
    },
    lifeIcon: {
        color: '#ff4b4b',
        fontSize: 24,
        // marginLeft: 20
    },
    lifeAmount: {
        color: '#ff4b4b',
        fontWeight: 'bold',
        paddingLeft: 2,
        paddingRight: 5
    }
}))