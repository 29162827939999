import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom';
import Text, { Small, Title } from '../components/Text';
import { Button } from '@material-ui/core';

export default function Resumo(props) {
    const history = useHistory();
    const classes = useStyles();

    const location = useLocation();
    const { acertos, erros, sucesso } = location.state;
    // const acertos=0, erros=10, sucesso=false;

    const voltar = () => {
        history.replace('/');
    }

    return (
        <div className={classes.root}>
            <div className={classes.mensagem}>
                <Title style={{ color: sucesso ? '#36b37e' : '#ff000a', fontSize: 28 }}>{sucesso ? 'Parabéns! Vamos em frente!' : 'Que pena! Não foi dessa vez.'}</Title>
                <Text>{sucesso ? 'Você passou para o próximo nível!' : 'Cada erro é um passo rumo ao sucesso. Continue praticando!'}</Text>
                <div className={classes.infos}>
                    <div>
                        <Small>Pontos</Small>
                        <p>{acertos > 0 ? '+' : ''}{acertos * 10}</p>
                    </div>

                    <div>
                        <Small>Erros</Small>
                        <p>{erros}</p>
                    </div>

                    <div>
                        <Small>Desempenho</Small>
                        <p>{(acertos * 100 / (acertos + erros)).toFixed(0)}%</p>
                    </div>
                </div>
            </div>
            <Button onClick={voltar} className={classes.buttom} fullWidth variant="contained" color="primary">Continuar</Button>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        maxHeight: '100vh',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0 20px',
        '& *': {
            textAlign: 'center'
        },
    },
    mensagem: {
        display: 'flex',
        width: '100%',
        maxHeight: 'calc(100vh - 200px)',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttom: {
        marginTop: 20,
        marginBottom: 20
    },
    infos: {
        display: 'flex',
        marginTop: 40,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&>div': {
            width: 'calc(33% - 8px)',
            borderRadius: 8,
            border: '2px solid #3b5998',
            backgroundColor: '#3b5998',
            '&>span': {
                color: '#FFF',
                margin: 0
            },
            '&>p': {
                margin: 0,
                padding: '15px 10px',
                borderRadius: 8,
                backgroundColor: '#FFF'
            }
        }
    }
}))