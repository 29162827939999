import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Avatar, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { Small, Title } from '../components/Text'
import DenseAppBar from '../components/AppBar'
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import Usuario from '../services/Usuario'
import Storage from '../services/Storage'
import Patentes from '../services/Patentes'
import Usuario from '../services/Usuario'
import { Alert, AlertTitle } from '@material-ui/lab'
import Conquistas from '../components/Conquistas'
// import MenuLateral from '../components/MenuLateral'

const ITEM_HEIGHT = 48;

export default function Perfil(props) {
    const classes = useStyles()
    const usuario = Storage.getUsuarioLogado();

    const desempenho = Storage.getDesempenho();

    const meusPontos = Storage.getPontosUsuarioLogado();
    const meuNivel = Patentes.getByPontos(meusPontos);

    const sair = () => {
        Usuario.sair();
        Storage.clear();
        props.history.replace('/login');
    }

    const editarPerfil = () => {
        props.history.push('/perfil/editar');
    }

    // const [openMenu, setOpenMenu] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <DenseAppBar title="Meu Perfil">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    // onClick={() => setOpenMenu(true)}
                    style={{ marginLeft: 'auto' }}
                >
                    {/* <MenuIcon /> */}
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem onClick={editarPerfil}>Editar Perfil</MenuItem>
                    <MenuItem onClick={sair}>Sair</MenuItem>
                </Menu>

            </DenseAppBar>


            <Grid container className={classes.container}>

                {/* <MenuLateral open={openMenu} setOpen={setOpenMenu} history={props.history} /> */}

                <Grid container item xs={12}>
                    <Grid item xs={3} className={classes.avatar}>
                        <Avatar alt={usuario.alun_nome} src={Storage.getDiretorioFotos() + usuario.alun_foto} />
                    </Grid>
                    <Grid container item xs={9} align="center">
                        <Grid item xs={12} className={classes.editContainer}>
                            <Typography variant="h5" component="h2">
                                {usuario.alun_nome}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Title style={{ marginTop: 30, paddingBottom: 0 }}>Estatísticas</Title>
                <Grid container item xs={12} className={classes.listagem}>
                    <Grid item xs={6}>
                        <div style={{ borderLeft: '3px solid rgba(249, 83, 83, .8)' }}>
                            <img alt="Respostas" className={classes.icon} src={require('../images/menu/conversation.png')} />
                            <div>
                                <p><strong>{desempenho.total}</strong></p>
                                <p>Respostas</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ borderLeft: '3px solid rgba(254, 220, 86, .8)' }}>
                            <img alt="Pontuação" className={classes.icon} src={require('../images/menu/raio.png')} />
                            <div>
                                <p><strong>{desempenho.pontuacao}</strong></p>
                                <p>Pontuação</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ borderLeft: '3px solid rgba(104, 159, 56, .8)' }}>
                            <img alt="Nível" className={classes.icon} src={meuNivel.imgBranco} />
                            <div>
                                <p><strong>{meuNivel.nome}</strong></p>
                                <p>Nível</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ borderLeft: '3px solid rgba(115, 126, 230, .8)' }}>
                            <img alt="Rank" className={classes.icon} src={require('../images/menu/002-ranking-1.png')} />
                            <div>
                                <p><strong>{Storage.getRankingUsuarioLogado()}</strong></p>
                                <p>Rank</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Title style={{ marginTop: 20, paddingBottom: 0 }}>Conquistas</Title>

                <Conquistas />

                <Alert severity="info" className={classes.justificativa}>
                    <AlertTitle className={classes.tituloJustificativa}>Potencialize seu Sucesso!</AlertTitle>
                    <Small>Dispomos de mais de 50.000 questões de direito comentadas! Não apenas entender a resposta correta, mas compreender os fundamentos é crucial. Continue utilizando o aplicativo diariamente e estará cada vez mais próximo da tão desejada aprovação.</Small>
                </Alert>

            </Grid>
        </div >
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '68px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        paddingBottom: 20,
        padding: '10px'
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            width: '80px',
            height: '80px',
            '& img': {
                borderRadius: 40,
                objectFit: 'cover'
            }
        }
    },
    listagem: {
        '& > div:nth-child(odd)': {
            paddingRight: 5
        },
        '& > div:nth-child(even)': {
            paddingLeft: 5,
        },
        '& > div > div': {
            display: 'flex',
            border: '1px solid #DDD',
            marginTop: 10,
            alignItems: 'center',
            padding: 10,
            borderRadius: 10
        },
        '& p': {
            margin: 0,
            paddingLeft: 10
        },
        '& p:last-child': {
            fontSize: 16,
            color: '#757575'
        }
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            fontSize: '1.5em',
            paddingLeft: 10,
        }
    },
    icon: {
        width: 28,
        height: 28
    },
    justificativa: {
        marginTop: 20
    },
    tituloJustificativa: {
        fontSize: 18
    }
}))
