import React from 'react'
import { Typography } from '@material-ui/core'
import Colors from '../services/Colors'

// import TextsmsIcon from '@material-ui/icons/Textsms';

export default function Text(props) {
    return (
        <Typography
            style={props.style || {}}
            className={props.className || ''}
            variant="body1"
            color="textSecondary"
            component="p"
        >
            {props.children}
        </Typography>
    )
}

export function Title(props) {
    return (
        <Typography color="textSecondary" component="h1" style={{ width: '100%', fontWeight: 'bold', padding: '10px 0', ...props.style }}>
            {props.children}
        </Typography>
    )
}

export function CopyrightBible() {
    return (
        <Help>
            A Sociedade Bíblica do Brasil trabalha para que a Bíblia
            esteja, efetivamente, ao alcance de todos e seja lida por
            todos. A SBB é uma entidade sem fins lucrativos, dedicada a
            disseminar a Bíblia e, por meio dela, promover o
            desenvolvimento integral do ser humano. Você também
            pode apoiar a Causa da Bíblia! ARC © 2009 Sociedade Bíblica
            do Brasil. Todos os direitos reservados.
        </Help>
    )
}

export function Help(props) {
    return (
        <Typography style={{ marginTop: 20 }} variant="caption" display="block" gutterBottom align="center" color="textSecondary">
            {props.children}
        </Typography>
    )
}

export function Small(props) {
    const otherProps = { ...props, children: null }
    return (
        <Typography {...otherProps} variant="caption" display="block" gutterBottom color="textSecondary">
            {props.children}
        </Typography>
    )
}

export function Versiculo(props) {
    const style = {
        margin: 0,
        padding: '6px 0',
        fontSize: 20
    }

    const styleVerse = {
        // fontWeight: 'bold',
        fontSize: 16,
        paddingRight: 4,
        color: '#8b8b8b',
        paddingTop: 4,
        paddingBottom: 1
    };

    const styleText = {
        lineHeight: 1.3
    }

    if (props.selecionado) {
        styleText.textDecoration = 'underline';
        styleText.textUnderlineOffset = 3;
    }

    if (props.color) {
        styleVerse.backgroundColor = Colors[props.color];
        styleText.backgroundColor = Colors[props.color];
    }

    return (
        <Typography variant="body1" component="p"
            style={style}
            {...props}
            color="initial"
            selecionado="false"
        >
            {props.versiculo && <span style={styleVerse}>{props.versiculo}.</span>}

            <span style={styleText}>{props.texto}</span>

            {/* <IconButton aria-label="delete" size="small">
                <TextsmsIcon style={{ color: '#b5b5b5' }} fontSize="inherit" />
            </IconButton> */}

        </Typography>
    )
}
