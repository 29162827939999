import React from 'react';
import { FormControl, InputLabel, FormHelperText, Input as InputText } from '@material-ui/core';


export default function Input(props) {
    const fieldName = props.name || props.placeholder.toLowerCase()
    const error = (props.errors && props.errors[fieldName]) || false

    const getMessage = errorType => {
        if (errorType === 'required') return 'Campo obrigatório'
        else if (errorType === 'maxLength') return `Digite no máximo ${props[errorType]} caracteres`
        else if (errorType === 'minLength') return `Digite no mínimo ${props[errorType]} caracteres`
    }

    const getRules = () => {
        const rules = {}

        if (props.maxLength) rules.maxLength = props.maxLength
        if (props.minLength) rules.minLength = props.minLength
        if (props.required) rules.required = props.required

        return rules
    }

    return (
        <FormControl>
            <InputLabel htmlFor={fieldName}>{props.placeholder}</InputLabel>
            <InputText multiline={props.multiline} onChange={props.onChange} defaultValue={props.defaultValue || ''} readOnly={!!props.readOnly} error={!!error} type={props.type || 'text'} name={fieldName} inputRef={props.inputRef(getRules())} />
            {error && <FormHelperText error={true}>{getMessage(error.type)}</FormHelperText>}
        </FormControl>
    )
}