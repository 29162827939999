import React from 'react'
import { Snackbar } from '@material-ui/core';
import Text from './Text';

export default function Alert(props) {
    return (
        <Snackbar open={props.open} transitionDuration={0} autoHideDuration={props.autoHideDuration} onClose={props.onClose}>
            <div style={{ marginBottom: 12, width: '100%', padding: '6px 16px', borderRadius: 4, fontWeight: 500, backgroundColor: props.erro ? '#f44336' : '#4caf50' }}>
                <Text style={{ fontSize: '0.875rem', color: '#fff', padding: '8px 0' }}>{props.children}</Text>
            </div>
        </Snackbar>
    )
}

