export default class Storage {
    static ITEM_NAME = 'bancoquestoes@app2024';
    static dados = false;

    static set(dados) {
        sessionStorage.setItem(Storage.ITEM_NAME, JSON.stringify(dados))
    }

    static clear() {
        sessionStorage.removeItem(Storage.ITEM_NAME)
    }

    static get() {
        return JSON.parse(sessionStorage.getItem(Storage.ITEM_NAME) || 'false')
    }

    static getUsuarioLogado() {
        const data = Storage.get();

        return data.aluno;
    }

    static setFotoPerfil(foto) {
        const data = Storage.get();

        const newData = { ...data, aluno: { ...data.aluno, foto } }

        Storage.set(newData);
    }

    static getAssuntos() {
        const data = Storage.get();

        return data.assuntos;
    }

    static setAssuntos(assuntos) {
        const data = Storage.get();

        const newData = { ...data, assuntos }

        Storage.set(newData);
    }

    static getDesempenho() {
        const data = Storage.get();

        return data.desempenho;
    }

    static setDesempenho(desempenho) {
        const data = Storage.get();

        const newData = { ...data, desempenho }

        Storage.set(newData);
    }

    static getDiretorioFotos() {
        const data = Storage.get();

        return data.avatar_path;
    }

    static anyToInt(any) {
        const n = parseInt(any);
        return !!n ? n : 0;
    }

    static getNumberOfUsers() {
        return Storage.getRanking().length
    }

    static getRanking() {
        const data = Storage.get();

        if (!data || !data.ranking) return [];

        return data.ranking;
    }

    static setRanking(ranking) {
        const data = Storage.get();

        const newData = { ...data, ranking }

        Storage.set(newData);
    }

    static getPontosUsuarioLogado() {
        const ranking = Storage.getRanking();
        let pontos = 0;

        ranking.map(e => {
            if (e.eu === '1') pontos = parseInt(e.pontuacao);
        });

        return pontos;
    }

    static getRankingUsuarioLogado() {
        const ranking = Storage.getRanking();
        let posicao = 0;

        ranking.map((e, i) => {
            if (e.eu === '1') posicao = i + 1;
        });

        return posicao;
    }

    static getFakeIdById(id) {
        return parseInt(id) * 1998;
    }

}