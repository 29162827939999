import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DenseAppBar from '../components/AppBar'
import Text, { Small } from '../components/Text'
import { useEffect } from 'react';
import Axios from '../services/Axios';
import Input from '../components/Input';
import { useForm } from 'react-hook-form';
import Alert from '../components/Alert'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { ContactSupportOutlined, MessageOutlined, PlusOneOutlined, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { Button } from '@material-ui/core'

export default function DuvidasESugestoes(props) {
    const classes = useStyles();
    const [sucesso, setSucesso] = useState(false);
    const [tipo, setTipo] = useState('Elogio');
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
    }, []);

    const voltar = () => {
        props.history.goBack();
    }

    const onSubmit = async dados => {
        const sugestao = { descricao: dados.descricao, tipo };

        const res = await Axios.post('/salvarSugestao.php', sugestao);

        if (res.data) {
            setSucesso('Enviado com sucesso.');
            setTimeout(() => {
                voltar();
            }, 1000);
        }
    }

    return (
        <div className={classes.root}>
            <DenseAppBar title="Dúvidas e Sugestões" onBack={voltar} />

            <Grid container className={classes.container}>
                <Small style={{ padding: '10px 0' }}>Envie suas sugestões e contribuições para o aplicativo LexLudus.</Small>

                <Grid container item xs={12}>
                    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                        <ToggleButtonGroup
                            value={tipo}
                            exclusive
                            onChange={(ev, value) => setTipo(value)}
                            className={classes.tipo}
                        >
                            <ToggleButton value="Elogio">
                                <ThumbUpAltOutlined />
                                <Text>Elogio</Text>
                            </ToggleButton>
                            <ToggleButton value="Sugestão">
                                <MessageOutlined />
                                <Text>Sugestão</Text>
                            </ToggleButton>
                            <ToggleButton value="Dúvida">
                                <ContactSupportOutlined />
                                <Text>Dúvida</Text>
                            </ToggleButton>
                            <ToggleButton value="Reclamação">
                                <ThumbDownAltOutlined />
                                <Text>Reclamação</Text>
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Input
                            placeholder="Descrição"
                            errors={errors}
                            inputRef={register}
                            name="descricao"
                            required={true}
                            minLength={3}
                            maxLength={1000}
                            multiline
                        />

                        <br /><Button variant="contained" color="primary" type="submit">Enviar</Button><br />
                    </form>
                </Grid>
            </Grid>

            <Alert erro={false} open={!!sucesso} autoHideDuration={6000} onClose={() => setSucesso(false)}>
                {sucesso}
            </Alert>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: 10
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    tipo: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        marginBottom: 30,
        marginTop: 20,
        '& button': {
            width: '25%',
            '& > span': {
                display: 'flex',
                flexDirection: 'column',
                '& p': {
                    fontSize: 12,
                    textTransform: 'capitalize',
                    marginTop: 6
                }
            }
        }
    }
}))

