import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Backdrop, CircularProgress, FormControlLabel, Checkbox, Link, FormHelperText } from '@material-ui/core';

import Input from '../components/Input'
import { Small, Title } from '../components/Text';

import Axios from '../services/Axios';
import Alert from '../components/Alert';

export default function Cadastro(props) {
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm();

    const [erro, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const onSubmit = async login => {
        setCarregando(true);

        const res = await Axios.post('/cadastrar.php', login);

        if (res.data?.sucesso) {
            setSucesso('Cadastrado com sucesso!');
            setDisabled(true);
            setTimeout(() => {
                props.history.push('/login');
            }, 1500);
        } else {
            setErro('E-mail já cadastrado.');
        }

        setCarregando(false);
    }

    const abrirTermos = event => {
        event.preventDefault();
        window.open('https://lexludus.com.br/api/upload/Termos%20de%20Uso%20do%20Aplicativo%20Lexludus.pdf')
    }

    return (
        <Paper className={classes.root} elevation={0}>
            {
                <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.center}>
                        <Title style={{ color: '#3b5998' }}>Cadastre-se</Title>
                    </div>

                    <Input
                        type="email"
                        placeholder="Email"
                        errors={errors}
                        inputRef={register}
                        required={true}
                    /><br />

                    <Input
                        type="text"
                        placeholder="Nome"
                        errors={errors}
                        inputRef={register}
                        required={true}
                    /><br />

                    <Input
                        type="password"
                        placeholder="Senha"
                        errors={errors}
                        inputRef={register}
                        required={true}
                    /><br />

                    <FormControlLabel
                        control={
                            <Checkbox errors={errors}
                                inputRef={register({ required: true })}
                                onChange={() => { }}
                                name="termos" />
                        }
                        label={<Small style={{ marginBottom: 0 }}>Eu li e concordo com os <Link onClick={abrirTermos}>termos de uso</Link>.</Small>}
                    />
                    {(errors && errors.termos) && <FormHelperText error={true}>Campo obrigatório</FormHelperText>}
                    <br />

                    <Button variant="contained" color="primary" type="submit" disabled={disabled}>Cadastrar</Button>

                    <div className={classes.centralize}>
                        <Button className={classes.botao} onClick={() => { props.history.push('/login') }}>Cancelar</Button>
                    </div>

                </form>
            }

            <Alert erro={true} open={!!erro} autoHideDuration={6000} onClose={() => setErro(false)}>
                {erro}
            </Alert>

            <Alert erro={false} open={!!sucesso} autoHideDuration={6000} onClose={() => setSucesso(false)}>
                {sucesso}
            </Alert>

            <Backdrop className={classes.backdrop} open={carregando}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        marginTop: 20,
        minHeight: 'calc(100vh + -80px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    botao: {
        marginTop: 10,
        marginBottom: 20
    },
    logo: {
        width: '80vw',
        maxWidth: 150,
        marginBottom: 70
    },
    centralize: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    }
}));