export default class Patentes {
    static LISTA = [
        { nivel: 1, nome: 'Bronze', img: require('../images/patentes/Bronze.svg'), imgBranco: require('../images/patentes/Bronze_branco.svg'), texto: 'Inicie sua jornada rumo à aprovação na OAB, construindo seu conhecimento a cada etapa.', pontos: { min: 0, max: 499 } },
        { nivel: 2, nome: 'Prata', img: require('../images/patentes/Prata.svg'), imgBranco: require('../images/patentes/Prata_branco.svg'), texto: 'Aprimore seus estudos jurídicos, desafiando-se a ir além do básico e se tornando um candidato mais dedicado.', pontos: { min: 500, max: 1199 } },
        { nivel: 3, nome: 'Ouro', img: require('../images/patentes/Ouro.svg'), imgBranco: require('../images/patentes/Ouro_branco.svg'), texto: 'Explore as nuances do campo jurídico, observando detalhes cruciais para sua preparação na OAB.', pontos: { min: 1200, max: 2099 } },
        { nivel: 4, nome: 'Safira', img: require('../images/patentes/Safira.svg'), imgBranco: require('../images/patentes/Safira_branco.svg'), texto: 'Aprofunde sua compreensão do Direito, aplicando o conhecimento adquirido nos estudos para a prova da OAB.', pontos: { min: 2100, max: 3399 } },
        { nivel: 5, nome: 'Rubi', img: require('../images/patentes/Rubi.svg'), imgBranco: require('../images/patentes/Rubi_branco.svg'), texto: 'Descubra a paixão pela preparação da OAB, encontrando pontos cruciais e se tornando um candidato ainda mais apaixonado.', pontos: { min: 3400, max: 4899 } },
        { nivel: 6, nome: 'Esmeralda', img: require('../images/patentes/Esmeralda.svg'), imgBranco: require('../images/patentes/Esmeralda_branco.svg'), texto: 'Explore a complexidade do Direito com uma mente curiosa, encontrando novas maneiras de resolver questões da OAB.', pontos: { min: 4900, max: 6599 } },
        { nivel: 7, nome: 'Obsidiana', img: require('../images/patentes/Obsidiana.svg'), imgBranco: require('../images/patentes/Obsidiana_branco.svg'), texto: 'Desafie-se a atingir um nível superior na preparação para a OAB, buscando conhecimento de maneira obstinada e aprofundada.', pontos: { min: 6600, max: 7999 } },
        { nivel: 8, nome: 'Diamante', img: require('../images/patentes/Diamante.svg'), imgBranco: require('../images/patentes/Diamante_branco.svg'), texto: 'Com sua dedicação, você está pronto para a prova da OAB! Compartilhe agora seu conhecimento e ajude outros estudantes nessa jornada.', pontos: { min: 8000, max: 999999 } }
    ];

    static getByPontos(pontos) {
        const p = Patentes.LISTA.filter(p => p.pontos.min <= pontos && p.pontos.max >= pontos);
        return p[0];
    }

}

