import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid'
import { Avatar, Backdrop, Button, CircularProgress } from '@material-ui/core'
import DenseAppBar from '../components/AppBar'

import Input from '../components/Input'

import Axios from '../services/Axios'
import Alert from '../components/Alert';

export default function NovoGrupo(props) {
    const classes = useStyles()
    const [foto, setFoto] = useState('https://lexludus.com.br/api/upload/grupo/default-grupo.png');
    const [erroUpload, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [enviando, setEnviando] = useState(false);

    const voltar = () => {
        props.history.goBack()
    }

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async dados => {
        setEnviando(true);

        const foto = document.getElementById('icon-button-file').files[0] || null;

        const res = await Axios.post('/salvarGrupo.php', { ...dados, foto }, { headers: { 'Content-Type': 'multipart/form-data' } });

        setEnviando(false);

        if (res.data) {
            setSucesso('Grupo criado com sucesso!');
            setTimeout(voltar, 1500);
        } else {
            setErro('Ops! Houve algum erro.');
        }

    }

    const showFoto = event => {
        const input = event.target;

        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = function (e) {
                setFoto(e.target.result);
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    return (
        <div className={classes.root}>
            <DenseAppBar title="Criar um Grupo" onBack={voltar} />

            <Grid container className={classes.container}>
                <Grid container item xs={12}>
                    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.center}>
                            <Avatar className={classes.img} src={foto} alt="Foto do grupo" />
                            <input onChange={e => showFoto(e)} accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" />
                            <label className={classes.editar} htmlFor="icon-button-file">
                                <span>Selecionar</span>
                            </label>
                        </div>

                        <Input
                            type="text"
                            placeholder="Nome"
                            errors={errors}
                            inputRef={register}
                            required={true}
                        /><br />

                        <Input
                            type="text"
                            placeholder="Descrição"
                            name="descricao"
                            errors={errors}
                            inputRef={register}
                            required={true}
                            multiline
                        /><br />

                        <Button disabled={!!sucesso} variant="contained" color="primary" type="submit">Criar grupo</Button><br />
                    </form>
                </Grid>

            </Grid>

            <Alert erro={true} open={erroUpload} autoHideDuration={6000} onClose={() => setErro(false)}>
                {erroUpload}
            </Alert>

            <Alert erro={false} open={sucesso} autoHideDuration={6000} onClose={() => setSucesso(false)}>
                {sucesso}
            </Alert>

            <Backdrop className={classes.backdrop} open={enviando}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: '10px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 40
    },
    img: {
        marginTop: 20,
        width: 120,
        height: 120,
        borderRadius: 100
    },
    editar: {
        marginBottom: 20,
        marginTop: 10,
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))

