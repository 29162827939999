import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid'
import { Avatar, Button } from '@material-ui/core'
import DenseAppBar from '../components/AppBar'

import Input from '../components/Input'

import Storage from '../services/Storage';
import Axios from '../services/Axios'
import Alert from '../components/Alert';
import { Help } from '../components/Text';

export default function Perfil(props) {
    const classes = useStyles()
    const usuario = Storage.getUsuarioLogado();

    const [foto, setFoto] = useState(usuario.alun_foto);
    const [erroUpload, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);

    const voltar = () => {
        props.history.goBack()
    }

    const { register, handleSubmit, errors } = useForm();

    // const [erro, setErro] = useState(false);
    // const [carregando, setCarregando] = useState(false);

    // const liberarAcesso = usuario => {
    //     // usuario.alun_set(usuario)
    //     window.location.href = '/'
    // }

    const onSubmit = async dados => {
        const res = await Axios.post('/salvarSenha.php', dados);

        if (res.data) {
            setSucesso('Senha alterada com sucesso.');
        } else {
            setErro('Ops! Houve algum erro.');
        }

    }

    const uploadFoto = async event => {
        const files = event.target.files;

        if (files.length === 0) return;

        const res = await Axios.post('/salvarFotoPerfil.php', { foto: files[0] }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (res.data) {
            if (!res.data.erro) {
                setFoto(res.data.foto);
                Storage.setFotoPerfil(res.data.foto);
            } else {
                setErro(res.data.erro);
            }
        }
    }

    return (
        <div className={classes.root}>
            <DenseAppBar title="Editar Perfil" onBack={voltar} />

            <Grid container className={classes.container}>
                <Grid container item xs={12}>
                    <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.center}>
                            <Avatar className={classes.img} src={Storage.getDiretorioFotos() + foto} alt={usuario.alun_nome} />

                            <input onChange={e => uploadFoto(e)} accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" />
                            <label className={classes.editar} htmlFor="icon-button-file">
                                <span>Editar</span>
                            </label>
                        </div>

                        <Input
                            type="email"
                            placeholder="Email"
                            errors={errors}
                            inputRef={register}
                            required={true}
                            readOnly={true}
                            defaultValue={usuario.alun_email}
                        /><br />

                        <Input
                            type="text"
                            placeholder="Nome"
                            errors={errors}
                            inputRef={register}
                            required={true}
                            readOnly={true}
                            defaultValue={usuario.alun_nome}
                        /><br />

                        <Input
                            type="password"
                            placeholder="Senha"
                            errors={errors}
                            inputRef={register}
                            required={true}
                            minLength={3}
                            maxLength={12}
                        />

                        <Help>Caso queira alterar a senha basta preencher o campo Senha e clicar no botão abaixo.</Help>

                        <br />

                        <Button variant="contained" color="primary" type="submit">Alterar Senha</Button><br />
                    </form>
                </Grid>

            </Grid>

            <Alert erro={true} open={erroUpload} autoHideDuration={6000} onClose={() => setErro(false)}>
                {erroUpload}
            </Alert>

            <Alert erro={false} open={sucesso} autoHideDuration={6000} onClose={() => setSucesso(false)}>
                {sucesso}
            </Alert>
        </div >
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: '10px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 40
    },
    img: {
        marginTop: 20,
        width: 120,
        height: 120,
        borderRadius: 100
    },
    editar: {
        marginBottom: 20,
        marginTop: 10,
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
}))

