import React, { useRef, useEffect } from 'react';

const AudioPlayer = React.forwardRef((props, ref) => {
    const audioRef = useRef(null);

    useEffect(() => {
        ref.current = {
            playSuccess: () => playAudio('success'),
            playError: () => playAudio('error')
        };
    }, [ref]);

    const playAudio = (audioSource) => {
        if (audioRef.current) {
            audioRef.current.src = audioSource === 'success' ? require('../audios/sucesso.mp3') : require('../audios/erro.mp3');
            audioRef.current.play();
        }
    };

    return (
        <div>
            <audio ref={audioRef} />
        </div>
    );
});

export default AudioPlayer;
