import React, { useState } from 'react'
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DenseAppBar from '../components/AppBar'
import Text, { Small } from '../components/Text'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useEffect } from 'react';
import Axios from '../services/Axios';

export default function AcessoAsLeis(props) {
    const classes = useStyles();
    const [dados, setDados] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const res = await Axios.get('/getLeis.php');

            if (res.data && Array.isArray(res.data)) {
                setDados(res.data);
            }
        }

        fetchData();
    }, []);

    const voltar = () => {
        props.history.goBack();
    }

    const abrirLei = link => {
        window.open(link);
    }

    return (
        <div className={classes.root}>
            <DenseAppBar title="Acesso às Leis" onBack={voltar} />

            <Grid container className={classes.container}>
                <Small style={{ padding: '10px 10px 0 10px' }}>Veja abaixo a lista com as principais leis a serem estudadas para a sua aprovação:</Small>

                <List component="nav" className={classes.lista}>
                    {
                        dados.map(e => (
                            <ListItem key={`lei-${e.link_id}`} button onClick={() => abrirLei(e.link_endereco)}>
                                <ListItemText><Text>{e.link_nome}</Text></ListItemText>
                                <ListItemIcon className={classes.icon}>
                                    <LaunchIcon />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </List>

            </Grid>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        padding: '10px 0'
    },
    lista: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    icon: {
        minWidth: 'auto',
        '& svg': {
            width: '.8em'
        }
    }
}))

