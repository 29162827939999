import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 10,
        paddingBottom: 6,
        height: 83
    },
    btn: {
        padding: '6px 6px 8px 6px',
        '&:not(.Mui-selected) img': {
            opacity: .55
        },
        '& .MuiBottomNavigationAction-label': {
            fontSize: '1.1em'
        }
    },
    icon: {
        width: 28,
        height: 28,
        paddingBottom: 5
    }
})

export default function SimpleBottomNavigation(props) {
    const history = useHistory()
    const classes = useStyles()
    const [value, setValue] = React.useState(props.current)

    useEffect(() => {
        // let newValue = props.current

        // if (newValue === '/aula') newValue = '/'

        setValue(props.current)
    }, [props])

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue)
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction
                onClick={() => history.push('/')}
                label="Início"
                value="/"
                className={classes.btn}
                icon={<img alt="Início" className={classes.icon} src={value === '/' ? require('../images/menu/003-home.png') : require('../images/menu/004-home-1.png')} />}
            />
            <BottomNavigationAction
                onClick={() => history.push('/estatisticas')}
                label="Desempenho"
                value="/estatisticas"
                className={classes.btn}
                icon={<img alt="Desempenho" className={classes.icon} src={value === '/estatisticas' ? require('../images/menu/speedometer-ativo.png') : require('../images/menu/speedometer.png')} />}
            />
            <BottomNavigationAction
                onClick={() => history.push('/grupos')}
                label="Grupos"
                value="/grupos"
                className={classes.btn}
                icon={<img alt="Grupos" className={classes.icon} src={value === '/grupos' ? require('../images/menu/grupo-ativo.png') : require('../images/menu/grupo.png')} />}
            />
            <BottomNavigationAction
                onClick={() => history.push('/ranking')}
                label="Ranking"
                value="/ranking"
                className={classes.btn}
                icon={<img alt="Ranking" className={classes.icon} src={value === '/ranking' ? require('../images/menu/002-ranking-1.png') : require('../images/menu/001-ranking.png')} />}
            />
            <BottomNavigationAction
                onClick={() => history.push('/perfil')}
                label="Perfil"
                value="/perfil"
                className={classes.btn}
                icon={<img alt="Perfil" className={classes.icon} src={value === '/perfil' ? require('../images/menu/007-user.png') : require('../images/menu/008-user-1.png')} />}
            />
        </BottomNavigation>
    )
}