import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Button, Divider, Drawer, ListSubheader } from '@material-ui/core';

import DenseAppBar from '../components/AppBar';
import Axios from '../services/Axios';
import Text, { Title } from '../components/Text';

export default function Grupos(props) {
    const classes = useStyles();
    const [grupos, setGrupos] = useState([]);
    const [openInfo, setOpenInfo] = useState(false);
    const [grupoSelecionado, setGrupo] = useState({});
    const [entrando, setEntrando] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const res = await Axios.get('/getGrupos.php');

            if (res.data && Array.isArray(res.data)) {
                setGrupos(res.data);
            }
        }

        fetchData();
    }, []);

    const novoGrupo = () => {
        props.history.push('/grupos/novo');
    };

    const verGrupo = grupo => {
        props.history.push(`/grupos/${grupo.grup_id}`, grupo);
    };

    const infoGrupo = grupo => {
        setGrupo(grupo);
        setOpenInfo(true);
    };

    const participar = async () => {
        setEntrando(true);
        const res = await Axios.post('/participarGrupo.php', { grup_id: grupoSelecionado.grup_id });

        if (res.data) verGrupo(grupoSelecionado);

        setEntrando(false);
    };

    const Item = props => (
        <ListItem button style={{ padding: '10px 20px' }} onClick={props.acao}>
            <ListItemAvatar>
                {props.avatar}
            </ListItemAvatar>
            <ListItemText
                className={classes.nome}
                primary={props.title}
                secondary={props.subtitle}
            />
        </ListItem>
    );

    return (
        <div className={classes.root}>
            <DenseAppBar title="Grupos" />
            <Grid container className={classes.container}>
                <List
                    subheader={<ListSubheader className={classes.header} component="div">Meus Grupos</ListSubheader>}
                    component="nav"
                    className={classes.listagem}>
                    {/* <Divider style={{ margin: '0 20px' }} component="li" /> */}
                    <Item
                        id="item-novo-grupo"
                        title="Criar um grupo"
                        subtitle="Crie um grupo e divirta-se com seus amigos enquanto aprende. Veja quem de vocês ficará no topo do ranking!"
                        avatar={<Avatar className={classes.avatar}><GroupAddIcon /></Avatar>}
                        acao={novoGrupo}
                    />
                    {grupos.filter(grupo => parseInt(grupo.participante)).map(grupo =>
                        <React.Fragment key={'meus-grupos-' + grupo.grup_id}>
                            <Divider className={classes.divider} variant="inset" component="li" />
                            <Item
                                title={grupo.grup_nome}
                                subtitle={grupo.grup_descricao}
                                avatar={<Avatar alt={grupo.grup_nome} src={`https://lexludus.com.br/api/upload/grupo/${grupo.grup_foto || 'default-grupo.png'}`} className={classes.avatar} />}
                                acao={() => verGrupo(grupo)}
                            />
                        </React.Fragment>
                    )}
                </List>
                <List
                    subheader={<ListSubheader className={classes.header} component="div">Outros Grupos</ListSubheader>}
                    component="nav"
                    className={classes.listagem}>
                    {/* <Divider style={{ margin: '0 20px' }} component="li" /> */}
                    {grupos.filter(grupo => !parseInt(grupo.participante)).map((grupo, index) =>
                        <React.Fragment key={'outros-grupos-' + grupo.grup_id}>
                            {(index > 0) && <Divider className={classes.divider} variant="inset" component="li" />}
                            <Item
                                title={grupo.grup_nome}
                                subtitle={grupo.grup_descricao}
                                avatar={<Avatar alt={grupo.grup_nome} src={`https://lexludus.com.br/api/upload/grupo/${grupo.grup_foto || 'default-grupo.png'}`} className={classes.avatar} />}
                                acao={() => infoGrupo(grupo)}
                            />
                        </React.Fragment>
                    )}
                </List>
                {/* <Small style={{ width: '100%', margin: 20, textAlign: 'center' }}>Os grupos são destinados a gerar engajamento entre colegas e amigos. Utilize-os com responsabilidade.</Small> */}
            </Grid>

            <Drawer
                anchor="bottom"
                className={classes.modal}
                open={openInfo}
                onClose={() => { if (!entrando) setOpenInfo(false) }}
            >
                <Title>{grupoSelecionado.grup_nome}</Title>
                <Text >{grupoSelecionado.grup_descricao}</Text>
                <Button fullWidth color="primary" variant="contained" disabled={entrando} onClick={participar} style={{ margin: '20px 0' }} >Participar</Button>
            </Drawer>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '68px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        paddingBottom: 20,
        padding: '10px 0'
    },
    listagem: {
        width: 'calc(100vw + 10px)',
        marginLeft: -5,
        marginRight: -5
    },
    divider: {
        marginLeft: 90,
        marginRight: 20
    },
    nome: {
        paddingLeft: 14,
        '& > span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%'
        },
        '& > p': {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }
    },
    avatar: {
        width: 50,
        height: 50,
        background: '#bdbdbd'
    },
    header: {
        backgroundColor: '#FFF',
        zIndex: 10,
        top: 54
    },
    modal: {
        '& > .MuiPaper-root': {
            padding: 10,
            maxHeight: '60vh',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
        }
    }
}));