import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Backdrop, CircularProgress, Link } from '@material-ui/core';

import Input from '../components/Input'
import { Title } from '../components/Text';

import Axios from '../services/Axios';
import Usuario from '../services/Usuario';
import Alert from '../components/Alert';

export default function Login(props) {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();

    const [erro, setErro] = useState(false);
    const [carregando, setCarregando] = useState(false);

    const liberarAcesso = usuario => {
        Usuario.set(usuario)
        window.location.href = '/'
        // props.history.replace('/', {})
    }

    const onSubmit = async login => {
        setCarregando(true);

        const res = await Axios.post('/login.php', login);

        if (res.data && res.data !== 'Não autenticado')
            liberarAcesso(res.data);
        else
            setErro(true);

        setCarregando(false);
    }

    return (
        <Paper className={classes.root} elevation={0}>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.center}>
                    <img alt="Logo" className={classes.logo} src={require('../images/logo-cinza.png')} />
                    <Title style={{ color: '#3b5998' }}>Entrar</Title>
                </div>

                <Input
                    type="email"
                    placeholder="Email"
                    errors={errors}
                    inputRef={register}
                    required={true}
                // defaultValue="guilhermee.andrade@hotmail.com"
                /><br />

                <Input
                    type="password"
                    placeholder="Senha"
                    errors={errors}
                    inputRef={register}
                    required={true}
                // defaultValue="123"
                /><br />

                <Link style={{ width: 'fit-content' }} onClick={() => { props.history.push('/recuperar-senha') }}>Esqueceu sua senha?</Link>
                <br /><br />

                <Button variant="contained" color="primary" type="submit">Entrar</Button>
                <Button className={classes.botao} onClick={() => { props.history.push('/cadastro') }}>Cadastre-se Agora</Button>

            </form>

            <Alert erro={true} open={erro} autoHideDuration={6000} onClose={() => setErro(false)}>
                E-mail ou senha incorretos.
            </Alert>

            <Backdrop className={classes.backdrop} open={carregando}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        marginTop: 20,
        minHeight: 'calc(100vh + -80px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    botao: {
        marginTop: 10,
        marginBottom: 20
    },
    logo: {
        width: '80vw',
        maxWidth: 150,
        marginBottom: 70
    }
}));