import React from 'react'
import { Button } from '@material-ui/core'

import Text from './Text'

import Usuario from '../services/Usuario';
import Storage from '../services/Storage';


export default function LoaddingPage(props) {

    const sair = () => {
        Usuario.sair();
        Storage.clear();
        window.location.href = '/';
    }

    return props.loading
        ?
        <div style={{
            position: 'fixed', width: '100%', height: '100%', display: 'flex',
            justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 20, backgroundColor: '#FFF'
        }}>
            {props.isOffline && <div style={{
                backgroundColor: 'red',
                color: 'rgb(255, 255, 255)',
                position: 'fixed',
                width: '100vw',
                top: 0,
                left: 0,
                textAlign: 'center',
                padding: 4
            }}>Sem conexão...</div>}

            <img style={{ width: '35vw', maxWidth: 200, }} src={require('../images/logo-cinza.png')} alt="Logo" />

            {
                props.message &&
                <div style={{ width: '100%', padding: 20, textAlign: 'center', backgroundColor: '#f57c00', marginTop: 40 }}>
                    <Text style={{ color: '#FFF' }}>{props.message}</Text><br />
                    <Button onClick={sair} variant="outlined" color="secondary">Sair</Button>
                </div>
            }

            <div style={{ width: 100, height: 40 }}></div>
        </div >
        :
        props.children
}

