import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';

const CustomProgress = withStyles((theme) => ({
    root: {
        width: '100%',
        height: 15,
        borderRadius: 10,
        margin: '15px 0',
        zIndex: 1
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        zIndex: 0,
        borderRadius: 10,
        backgroundColor: theme.palette.primary//'#1a90ff',
    },
}))(LinearProgress);

const BorderLinearProgress = props => {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ paddingRight: 10, margin: 0, width: '70%', textAlign: 'left'}} variant="caption" display="block" gutterBottom align="center" color="textSecondary">
            {props.label}
        </Typography>
        <CustomProgress {...props} />
        <Typography style={{ paddingLeft: 10, margin: 0, width: '20%', textAlign: 'left'}} variant="caption" display="block" gutterBottom align="center" color="textSecondary">
            {props.text || props.value + '%'}
        </Typography>
    </div>;
};

export default BorderLinearProgress;