import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DenseAppBar from '../components/AppBar';
import { FormControl, Grid, MenuItem, Paper, Select } from '@material-ui/core';
import { VictoryPie } from 'victory';

import Text, { Title } from '../components/Text';
import { useEffect } from 'react';
import Axios from '../services/Axios';
import Storage from '../services/Storage';
import { useState } from 'react';
// import SkeletonEstatisticas from '../components/SkeletonEstatisticas';
import BorderLinearProgress from '../components/BorderLinearProgress';

const INITIAL_RESOLUCOES = {
    total: 10,
    acertos: 9,
    erros: 1,
    taxa: 90,
    pontuacao: [{
        color: '#DDD',
        y: 100
    },
    {
        color: 'red',
        y: 0
    }]
}

const niveis = {
    baixo: {
        porcentagem: 50,
        texto: 'Baixo',
        cor: '#ef4d50'
    },
    medio: {
        porcentagem: 80,
        texto: 'Médio',
        cor: '#fdb121'
    },
    alto: {
        texto: 'Alto',
        cor: '#199a6d'
    }
}

export default function Estatisticas(props) {
    const classes = useStyles();
    // const [carregando, setCarregando] = useState(false);
    const [resolucoes, setResolucoes] = useState(INITIAL_RESOLUCOES);
    const [periodo, setPeriodo] = useState(365);

    const getNivel = (porcentagem) => {
        if (porcentagem <= niveis.baixo.porcentagem) {
            return niveis.baixo;
        } else if (
            porcentagem > niveis.baixo.porcentagem &&
            porcentagem <= niveis.medio.porcentagem
        ) {
            return niveis.medio;
        } else {
            return niveis.alto;
        }
    }

    const showDesempenho = desempenho => {
        const nivel = getNivel(desempenho.taxa);

        const dados = {
            ...desempenho,
            nivel,
            pontuacao: [{
                color: '#DDD',
                y: 100 - desempenho.taxa
            },
            {
                color: nivel.cor,
                y: desempenho.taxa
            }]
        }

        setResolucoes(dados);
    }

    const loadDesempenho = async () => {
        const response = await Axios.get('/getDesempenho.php', { params: { periodo } });
        const data = response.data;

        if (!data || !data.taxa) return;

        showDesempenho(data);

        if (periodo === 365)
            Storage.setDesempenho(data);
    }

    useEffect(() => {
        const desempenho = Storage.getDesempenho();
        showDesempenho(desempenho);

        loadDesempenho();

        const chart = document.querySelector('#main-chart svg');
        chart.setAttribute('height', 200);
        chart.setAttribute('viewBox', '0 0 400 200');
    }, [])

    useEffect(() => {
        loadDesempenho();
    }, [periodo]);

    return (
        <div className={classes.root}>
            <DenseAppBar title="Desempenho" />
            <Grid container className={classes.container}>

                <FormControl variant="outlined" className={classes.select}>
                    <Select
                        id="select-periodo"
                        value={periodo}
                        onChange={event => setPeriodo(event.target.value)}
                    >
                        <MenuItem value={7}>Últimos 7 dias</MenuItem>
                        <MenuItem value={30}>Últimos 30 dias</MenuItem>
                        <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        <MenuItem value={365} selected>Último ano</MenuItem>
                    </Select>
                </FormControl>

                {/* {carregando && <SkeletonEstatisticas />} */}

                <Paper elevation={0} style={{ width: '100%', backgroundColor: '#fff8f0', padding: 20 }}>
                    <Title style={{ padding: 0 }}>Geral</Title>

                    <div className={classes.chartContainer} id="main-chart">
                        <div className={classes.chartDescription}>
                            <Text style={{ fontWeight: 'bold' }}>{resolucoes.taxa.toFixed(1)}%</Text>
                            <Text>{resolucoes.nivel?.texto}</Text>
                        </div>
                        <VictoryPie
                            data={resolucoes.pontuacao}
                            colorScale={resolucoes.pontuacao.map(e => e.color)}
                            padAngle={1}
                            innerRadius={120}
                            cornerRadius={100}
                            startAngle={90}
                            endAngle={-90}
                            labelComponent={<span></span>}
                        />
                    </div>
                </Paper>

                <Paper elevation={0} style={{ padding: 20, marginTop: 20, width: '100%', backgroundColor: '#f1f5ff' }}>
                    <Title style={{ padding: '0 0 20px 0' }}>Resoluções</Title>
                    <BorderLinearProgress label="Resoluções" value={100} text={resolucoes.total} variant="determinate" />
                    <BorderLinearProgress label="Acertos" value={resolucoes.acertos} text={resolucoes.acertos} variant="determinate" />
                    <BorderLinearProgress label="Erros" value={resolucoes.erros} text={resolucoes.erros} variant="determinate" />
                    <BorderLinearProgress label="Taxa de Acertos" value={resolucoes.taxa} text={resolucoes.taxa.toFixed(1)} variant="determinate" />
                </Paper>

            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '68px 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
        paddingBottom: 20,
        padding: '10px'
    },
    chartContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end'
    },
    chartDescription: {
        position: 'absolute',
        textAlign: 'center'
    },
    select: {
        width: '100%',
        marginBottom: 20
    }
}));