import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, withStyles } from '@material-ui/core'
import DenseAppBar from '../components/AppBar'

import Axios from '../services/Axios'
import { useLocation } from 'react-router-dom';
import { Small } from '../components/Text';
import Patentes from '../services/Patentes'
import Storage from '../services/Storage'
import SkeletonRanking from '../components/SkeletonRanking'

export default function VerGrupo(props) {
    const classes = useStyles();
    const [participantes, setParticipantes] = useState([]);

    const location = useLocation();
    const grupo = location.state || {};

    useEffect(() => {
        if (!grupo.grup_id) {
            props.history.push('/')
            return;
        }

        async function fetchData() {
            const res = await Axios.get('/getRanking.php', { params: { grup_id: grupo.grup_id } });

            if (res.data && Array.isArray(res.data)) {
                setParticipantes(res.data);
            }
        }

        fetchData();
    }, []);

    const voltar = () => {
        props.history.goBack()
    }

    const getPosition = position => {
        if (position === 0) {
            return <img alt="1º" className={classes.imgPosition} src={require('../images/medal-1.png')} />
        } else if (position === 1) {
            return <img alt="2º" className={classes.imgPosition} src={require('../images/medal-2.png')} />
        } else if (position === 2) {
            return <img alt="3º" className={classes.imgPosition} src={require('../images/medal-3.png')} />
        } else {
            return <span style={{ fontWeight: 'bold' }}>{position + 1}</span>
        }
    }

    return (
        <div className={classes.root}>
            <DenseAppBar title="Informações do Grupo" onBack={voltar} />

            <Grid container className={classes.container}>

                <ListItem alignItems="flex-start">
                    <ListItemAvatar style={{ width: 80, height: 80 }}>
                        <Avatar style={{ marginRight: 14, width: 'calc(100% - 14px)', height: 'calc(100% - 14px)' }} alt={grupo.grup_nome} src={`https://lexludus.com.br/api/upload/grupo/${grupo.grup_foto || 'default-grupo.png'}`} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={grupo.grup_nome}
                        secondary={<Small>{grupo.grup_descricao}</Small>}
                    />
                </ListItem>

                <List
                    subheader={<ListSubheader style={{ backgroundColor: '#FFF', zIndex: 99 }} component="div">Ranking do Grupo</ListSubheader>}
                    component="nav"
                    style={{ width: 'calc(100vw - 10px)', marginLeft: -10, marginRight: -10 }}>

                    {
                        participantes.length === 0 ? <SkeletonRanking /> :
                            participantes.map((aluno, position) => (
                                <ListItem button style={{ padding: '10px 20px' }} key={position}>
                                    <ListItemAvatar>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ marginLeft: -8, width: 42, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {getPosition(position)}
                                            </div>
                                            <Badge
                                                className={classes.badge}
                                                overlap="rectangular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={<SmallAvatar alt="Patente" src={Patentes.getByPontos(aluno.pontuacao).imgBranco} />}
                                            >
                                                <Avatar alt={aluno.alun_nome} src={Storage.getDiretorioFotos() + aluno.alun_foto} className={classes.avatar} />
                                            </Badge>
                                        </div>
                                    </ListItemAvatar>
                                    <ListItemText
                                        className={classes.nome}
                                        primary={aluno.alun_nome}
                                        secondary={`${aluno.pontuacao} pontos`}
                                    />
                                </ListItem>
                            ))
                    }
                </List>

            </Grid>
        </div >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '58px 0 0 0'
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 40,
        padding: 0
    },
    avatar: {
        width: 50,
        height: 50,
        background: '#bdbdbd'
    },
    badge: {
        '& > span': {
            position: 'absolute',
            right: 4,
            bottom: 4,
            padding: 0
        }
    },
    nome: {
        paddingLeft: 20,
        '& span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%'
        }
    }
}))

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        borderRadius: 0,
        '& img': {
            objectFit: 'contain',
        }
    },
}))(Avatar);
