import { Avatar, makeStyles, Paper } from '@material-ui/core';
import React from 'react'
import Avaliacao from './Avaliacao';
import Text, { Title } from './Text';

export default function CardHorizontal(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.container} onClick={props.onClick}>
            <div className={classes.foto} style={{ backgroundColor: props.backgroundColor || '#DDD' }}>
                <Avatar
                    className={props.fotoFull ? classes.imgFull : classes.img}
                    variant="square" alt={props.nome} src={props.foto}
                    style={{ filter: props.ativo ? 'none' : 'grayscale(1)' }}
                />
            </div>
            <div className={classes.texto}>
                {props.nome && <Title style={props.nomeStyle || {}}>{props.nome}</Title>}
                {props.descricao && <Text>{props.descricao}</Text>}
                {props.avaliacao && <Avaliacao avaliacao={props.avaliacao} />}
            </div>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        marginTop: 10,
        width: '100%',
        cursor: 'pointer'
    },
    foto: {
        display: 'flex',
        width: 90,
        minWidth: 90,
        minHeight: 90,
        justifyContent: 'center',
        alignItems: 'center',
        // padding: 6,
        borderRadius: '4px 0 0 4px'
    },
    img: {
        width: '70%',
        height: '70%',
        backgroundColor: 'transparent',
        '& img': {
            objectFit: 'contain'
        }
    },
    imgFull: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        '& img': {
            objectFit: 'cover',
            borderRadius: '4px 0 0 4px'
        }
    },
    texto: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        '& > h1': {
            padding: '0 !important',
            fontSize: 18,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            justifyContent: 'space-evenly',
        },
        '& > p': {
            fontSize: 16
        }
    }
}));